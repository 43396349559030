import { getRandomAlphanumericAsciiId } from './utility';

// Global Data
import globalValues from '../static-data/global-values.json';

const adobeApp = globalValues?.data?.corusAdobeAnalyticsSettings?.adobe_app || {};
const appName = adobeApp?.name ?? 'corus';

const serializationIdPrefix = globalValues?.data?.corusAdobeAnalyticsSettings?.adobe_misc_settings?.serializationIdPrefix || 'CE';

function satelliteTrack(scrollViewTrack = false, shouldCopyCodexData = true) {
	// eslint-disable-next-line no-underscore-dangle
	if (typeof window._satellite !== 'undefined') {
		if (shouldCopyCodexData) {
			// eslint-disable-next-line no-underscore-dangle
			const copyCodexData = { ...window.codexData };

			if (scrollViewTrack) {
				copyCodexData.content = {};
			}

			window._satellite.track('track', copyCodexData);

			return;
		}

		window._satellite.track('track');
	}
}

/**
 * @summary Create an ID suitable for `flow.serializationid`.
 * ID is of the form `"{2-letter key}{4 random alphanumeric characters}{time in ms}"`.
 * @param {string} idPrefix Prefix for the ID.
 * @returns {string}
 */
export function createSerializationId(idPrefix) {
	const id = getRandomAlphanumericAsciiId(4);
	const timeMs = Date.now();

	return `${idPrefix}${id}${timeMs}`;
}

/**
 * @summary Send page views
 */
export function sendPageView(routerPathName) {
	window.codexData = window.codexData || {};

	window.codexData.events = window.codexData.events || {};

	const specialPages = ['search', 'recipe'];
	const isSpecialPage = specialPages.some((page) => routerPathName.includes(page));
	const isArchivePage = window.codexData.view.type && window.codexData.view.type === 'archive';
	const isHomePage = window.codexData.view.type && window.codexData.view.type === 'homepage';
	const isPageWithFiltering = isSpecialPage && (isArchivePage || isHomePage);

	// update the view urls on special pages (search page and recipe page with filtering)
	if (isPageWithFiltering) {
		window.codexData.view.url = window.location.href;
	}

	// send page view events except for special pages with filtering
	if (!isPageWithFiltering) {
		// send the view event to adobe analytics
		window.codexData.events = {
			...window.codexData.events,
			view: true,
		};

		// clear the search data for recipe page
		if (routerPathName.includes('/recipe/')) {
			window.codexData.events.search = false;
			window.codexData.events.searchFilter = false;
			if (window.codexData.search) {
				delete window.codexData.search;
			}
		}

		// send the track
		if (typeof _satellite !== 'undefined') {
		// eslint-disable-next-line no-undef
			_satellite.track('track');
		}
	}
}

/**
 * @summary Handle interaction event
 * @param {string} module interactive module type ex: share
 * @param {string} name interactive name
 */
function handleInteractionEvent(module, name) {
	window.codexData.events = window.codexData.events || {};
	window.codexData.events.interaction = true;
	window.codexData.events.view = false;

	if (module) {
		if (module === 'share') {
			window.codexData.events.share = true;
			window.codexData.share = { type: name };
			window.codexData.interaction = {
				name: (`${window.codexData.app.name}:${window.codexData.view.name}:${module}`).replace(/(\W)\W*/g, '$1'),
			};
		} else if (module === 'filter') {
			window.codexData.interaction = {
				name: (`${window.codexData.app.name}:${window.codexData.view.name}:${module}:${name}`).replace(/(\W)\W*/g, '$1'),
			};
		} else if (module === 'filterSearch') {
			window.codexData.events.search = false;
			window.codexData.events.searchFilter = false;
			// delete search term position on filter
			delete window.codexData.search.position;
			window.codexData.interaction = {
				name: (`${window.codexData.app.name}:search:filter:${name}`).replace(/(\W)\W*/g, '$1'),
			};
		}
	} else {
		window.codexData.interaction = {
			name: (`${window.codexData.app.name}:${name}`).replace(/(\W)\W*/g, '$1'),
		};
	}
	// send the track
	if (typeof _satellite !== 'undefined') {
		const copyCodexData = { ...window.codexData };
		window._satellite.track('track', copyCodexData);
	}
	delete window.codexData.interaction;
}

/**
 * @summary Send page interactions
 */
export function sendPageInteraction() {
	if (window.codexData) {
		// Trigger analytics for links, buttons or input fields when it has 'data-analytics' attribute
		const interactiveItems = document.querySelectorAll('a[data-analytics], button[data-analytics]');
		if (interactiveItems !== null) {
			interactiveItems.forEach((interactiveItem) => {
				const { name = '', module = '' } = JSON.parse(interactiveItem.dataset.analytics);

				// set event listener for link or button click
				interactiveItem.addEventListener('click', () => {
					handleInteractionEvent(module, name);
				});
			});
		}
	}
}

/**
 * @summary Handle filter interaction
 * @param {string} module
 * @param {string} name
 */
export const handleFilterInteraction = (module, name) => {
	handleInteractionEvent(module, name);
};

/**
 * @summary Use this function as a hook for successful newsletter signups.
 * Call this function where you know the signup is successful, and it will take care of the analytics fields.
 * @returns {void}
 */
export function handleSuccessMessageAnalytics() {
	if (window.codexData) {
		// Get the app name from the codexData object, otherwise use `'corus'` as a default.
		const namePrefix = appName;

		// Get the 2-letter app ID from the codexData object, otherwise use `'CE'` as a default.
		const idPrefix = serializationIdPrefix;

		const oldCodexData = window.codexData;

		const newCodexData = {
			...oldCodexData,
			events: {
				flowComplete: true,
				flowStep: true,
				newsletterSignup: true,
				view: false, // newsletter signup is not a page view
			},
			flow: {
				name: `${namePrefix}-newsletter-signup`,
				serializationid: createSerializationId(idPrefix),
				stepName: 'complete',
			},
		};

		window.codexData = newCodexData;

		satelliteTrack();
	}
}

/**
 * @summary Function to send search input data
 * @param {object} searchData
 * @returns {void}
 */
export function sendSearchData(searchData) {
	if (window.codexData) {
		window.codexData.search = window.codexData.search || {};
		// default options for both search bar and search filter
		// set the view and interaction to false
		window.codexData.events.view = false;
		window.codexData.events.interaction = false;
		// set the search events
		window.codexData.events.search = true;
		window.codexData.events.searchFilter = true;

		// If we have a search filters, delete search filters
		// and the search position if there is one
		if (window.codexData.search.filters) {
			delete window.codexData.search.filters;
			delete window.codexData.search.position;
		}

		// set the search events to false on recipe pages
		if (window.codexData.view.name === 'recipe') {
			window.codexData.events.search = false;
		}

		// set the view to true on recipe page with no filters
		if (window.codexData.view.name === 'recipe' && searchData.searchFilters === undefined) {
			window.codexData.events.view = true;
			window.codexData.events.searchFilter = false;
			if (window.codexData.search) {
				delete window.codexData.search;
			}
		}

		// set the search data
		if (searchData.searchTerm !== undefined) {
			window.codexData.search.term = searchData.searchTerm;
			// update the view name with new search term
			window.codexData.view.name = searchData.searchTerm;
		}
		// update the search results
		if (searchData.searchResults !== undefined) {
			window.codexData.search.results = searchData.searchResults;
		}
		// update the search term position
		if (searchData.searchPosition !== undefined) {
			window.codexData.search.position = parseInt(searchData.searchPosition, 10);
		}
		// add search filters
		if (searchData.searchFilters !== undefined) {
			window.codexData.events.search = false;
			window.codexData.search.filters = searchData.searchFilters;
		}

		// send the data
		satelliteTrack();
	}
}

/**
 * @summary Handle search analytics data for search
 * @param {string} location
 */
export const handleSearchData = (location) => {
	// set the time out time, we need to delay the call if
	// search is coming from the search menu icon in the header on search pages
	const time = location && location === 'menu' ? 5000 : 1500;
	// find search data
	setTimeout(() => {
		// get search input and filter data
		const searchInputData = document.querySelector('div[data-search-input]');
		const searchFilterData = document.querySelector('div[data-search-filters]');
		const searchData = {};
		// add search term and results
		if (searchInputData !== null) {
			const searchTermData = JSON.parse(searchInputData.dataset.searchInput);
			searchData.searchTerm = searchTermData.searchTerm;
			searchData.searchResults = searchTermData.searchResults;
			// Add search term position if the search term was used from predective search
			if (searchTermData.searchPosition && searchTermData.searchPosition !== null) {
				searchData.searchPosition = searchTermData.searchPosition;
			}
		}
		// add searh filter data
		if (searchFilterData !== null) {
			const searchTermFilterData = JSON.parse(searchFilterData.dataset.searchFilters);
			searchData.searchFilters = searchTermFilterData.searchFilters;
			// remove the search position
			if (typeof window.codexData.search !== 'undefined') {
				delete window.codexData.search.position;
			}
		}
		sendSearchData(searchData);
	}, time);
};
