import PropTypes from 'prop-types';

import SearchIcon from '../../inline-images/search-icon.svg';

/**
 * @summary The header search menu toggle button.
 * @param {import('./HeaderTypes').HeaderSearchToggleButtonProps} props
 * @returns {JSX.Element}
 */
export default function HeaderSearchToggleButton({ toggleSearchMenu, isSearchOpen }) {
	return (
		<div className="header-search">
			<div className="header-search-title">Search</div>
			<button
				type="button"
				onClick={toggleSearchMenu}
				className="header-search-button"
				aria-label="Search Food Network Canada"
				aria-expanded={isSearchOpen}
			>
				<SearchIcon />
			</button>
			<button
				type="button"
				onClick={toggleSearchMenu}
				className="header-search-close-button btn-close"
				aria-label="Close search menu"
			/>
		</div>
	);
}

HeaderSearchToggleButton.propTypes = {
	toggleSearchMenu: PropTypes.func,
	isSearchOpen: PropTypes.bool,
};

HeaderSearchToggleButton.defaultProps = {
	toggleSearchMenu: () => {},
	isSearchOpen: false,
};
