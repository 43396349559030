const GTM_ID = 'GTM-WFSS3CM';

export const pageView = (url) => {
	window.dataLayer.push({
		event: 'pageview',
		page: url,
	});
};

export default GTM_ID;
