import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useRouter } from 'next/router';
import * as gtm from '../../lib/gtm';

const GoogleTagManager = ({ children }) => {
	const router = useRouter();

	useEffect(() => {
		router.events.on('routeChangeComplete', gtm.pageView);

		return () => {
			router.events.off('routeChangeComplete', gtm.pageView);
		};
	}, [router.events]);

	return children;
};

export default GoogleTagManager;

GoogleTagManager.propTypes = {
	children: PropTypes.node,
};

GoogleTagManager.defaultProps = {
	children: undefined,
};
