import PropTypes from 'prop-types';
import Footer from '../Footer/Footer';
import Header from '../Header/Header';
import SeoYoast from '../Seo/SeoYoast';

export default function SiteLayout({ children }) {
	// eslint-disable-next-line react/prop-types
	const { article, page } = children?.props;

	const seo = page ? page?.seo : article?.seo;

	// eslint-disable-next-line react/prop-types
	const className = `main page-${children.type.name}`;

	return (
		<>
			<SeoYoast seo={seo} />
			<Header />
			<div className={className} id="main" role="main">
				<div className="content" role="document">{children}</div>
				<Footer />
			</div>
		</>
	);
}

SiteLayout.propTypes = {
	children: PropTypes.shape({}).isRequired,
};
