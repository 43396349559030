/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from 'prop-types';
import { ApolloProvider } from '@apollo/client';
import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { appWithTranslation } from 'next-i18next';
import AdsProvider, { getAdTargetingArguments } from '../components/Ad/AdsProvider';
import { useApollo, initializeApollo } from '../utils/apolloClient';
import { APP_QUERY } from '../components/AppQuery/AppQuery';
import SiteLayout from '../components/Layout/SiteLayout';
import AdobeAnalytics from '../components/AdobeAnalytics/AdobeAnalytics';
import GoogleTagManager from '../components/GoogleTagManager/GoogleTagManager';
import AppContext from '../context/AppContext';

import '../styles/globals.scss';
import '../styles/_templates.scss';
import '../components/index.scss';
import init from '../utils/sentry';
import { addTrailingSlash, hideGoogleCaptchaBadge } from '../utils/utility';
import useRouterUrlHistory from '../utils/useRouterUrlHistory';
import globalValues from '../static-data/global-values.json';
import { isUsTraffic } from '../utils/geoBlock';

init();

function App({
	Component, pageProps, asPath, isUs, playerVersion,
}) {
	// Grab hostUrl from backend (same as frontend_url).
	// Do not get it from headers on the serverside, you will not get client-facing url all the times.
	const hostUrl = globalValues?.data?.themeSettings?.themeConfig?.frontendUrl;
	const globalState = { ...pageProps?.initialApolloState, hostUrl, asPath };

	const apolloClient = useApollo(globalState);
	const Layout = Component.layout || SiteLayout;
	const router = useRouter();

	// Ad targeting arguments
	const adTargeting = getAdTargetingArguments(router, pageProps?.article, pageProps?.recipe);

	// Router History
	useRouterUrlHistory();

	useEffect(() => {
		// eslint-disable-next-line no-unused-vars
		router.events.on('routeChangeComplete', (url) => { hideGoogleCaptchaBadge(); });
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<AppContext.Provider
				value={{
					hostUrl: addTrailingSlash(hostUrl),
					asPath: asPath.replace(/^\//g, ''),
					isUsTraffic: isUs,
					playerVersion,
					adTargeting,
				}}
			>
				<AdobeAnalytics>
					<GoogleTagManager>
						<AdsProvider {...pageProps}>
							<ApolloProvider client={apolloClient}>
								<Layout {...pageProps}>
									<Component {...pageProps} />
								</Layout>
							</ApolloProvider>
						</AdsProvider>
					</GoogleTagManager>
				</AdobeAnalytics>
			</AppContext.Provider>
		</>
	);
}

App.getInitialProps = async (appContext) => {
	let playerVersion = null;

	// Run App Query before proceeding with the request
	// Fetches redirection & other necessary info like videoplayer version
	if (typeof window === 'undefined' && appContext.ctx.res.writeHead) {
		const apolloClient = initializeApollo();
		const { data: { redirection, corusVideoplayerSettings } } = await apolloClient.query({
			query: APP_QUERY,
			variables: {
				url: appContext.router.asPath,
			},
		});

		playerVersion = corusVideoplayerSettings?.cesVideoplayerMain?.playerversion;

		// Add Redirection on Server side
		if (redirection) {
			appContext.ctx.res.writeHead(redirection.code, { Location: redirection.target });
			appContext.ctx.res.end();
		}
	}

	return {
		asPath: typeof window === 'undefined' ? appContext?.ctx?.asPath : `${window.location.pathname}${window.location.search}`,
		isUs: typeof window === 'undefined' && isUsTraffic(appContext?.ctx), // Retrieving Cloudfront country code for context api
		playerVersion,
	};
};

App.propTypes = {
	Component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
	pageProps: PropTypes.object,
	asPath: PropTypes.string,
	isUs: PropTypes.bool,
	playerVersion: PropTypes.string,
};

App.defaultProps = {
	pageProps: {},
	asPath: '',
	isUs: false,
	playerVersion: '7.6.4',
};

// !!! DO NOT MAKE ADDITIONAL CHANGES TO APP BELOW THIS LINE.
// !!! ONLY H.O.C. WRAPPERS ALLOWED PAST THIS POINT.
// !!! IF YOU MAKE ADDITIONAL CHANGES AFTER `export default`, THE APP WILL *NOT* INCLUDE THEM.

export default appWithTranslation(App);
