import PropTypes from 'prop-types';

import NavMenu from './NavMenu';
import SocialLinks from '../Social/SocialLinks';

/**
 * @summary The menu, including the nav menu and social networking sites links.
 * @param {import('./HeaderTypes').MenuProps} props
 * @returns {JSX.Element}
 */
export default function Menu({
	mainMenuItems = [],
	isMobileOnly = false,
	isDesktopOnly = false,
}) {
	return (
		<nav
			className={[
				'header-navmenu',
				isMobileOnly ? 'd-block d-none-lg' : null,
				isDesktopOnly ? 'd-none d-block-lg' : null,
			].join(' ')}
			aria-label="Main menu"
		>
			<ul className="nav-menu">
				<NavMenu mainMenuItems={mainMenuItems} />
			</ul>
			<div className="header-social">
				<div className="social-title">
					Follow Us
				</div>
				<SocialLinks types={['instagram', 'tiktok', 'facebook']} />
			</div>
		</nav>
	);
}

Menu.propTypes = {
	mainMenuItems: PropTypes.arrayOf(PropTypes.shape({
		node: PropTypes.shape({
			url: PropTypes.string.isRequired,
			target: PropTypes.string,
			label: PropTypes.string.isRequired,
		}),
	})).isRequired,
	isMobileOnly: PropTypes.bool,
	isDesktopOnly: PropTypes.bool,
};

Menu.defaultProps = {
	isMobileOnly: false,
	isDesktopOnly: false,
};
