import Link from 'next/link';

/**
 * @summary The header logo.
 * @returns {JSX.Element}
 */
export default function HeaderLogo() {
	return (
		<div className="header-logo">
			<Link
				href="/"
				className="header-logo-link"
				rel="home"
				aria-label="Link to Food Network Canada homepage"
			>

				<img
					className="header-logo-image"
					src="/images/food-network-canada-logo.svg"
					alt="Food Network Canada home"
					title="Food Network Canada home"
				/>

			</Link>
		</div>
	);
}
