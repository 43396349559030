import FacebookIcon from './Icon/FacebookIcon';
import InstagramIcon from './Icon/InstagramIcon';
import EmailIcon from './Icon/EmailIcon';
import PinterestIcon from './Icon/PinterestIcon';
import PrintIcon from './Icon/PrintIcon';
import TiktokIcon from './Icon/TiktokIcon';
import TwitterIcon from './Icon/TwitterIcon';
import YoutubeIcon from './Icon/YoutubeIcon';

const Social = {
	facebook: {
		image: '/icons/facebook-social-icon.svg',
		displayName: 'Facebook',
		url: 'https://www.facebook.com/foodnetworkcanada',
		Icon: FacebookIcon,
	},
	pinterest: {
		image: '/icons/pinterest-social-icon.svg',
		displayName: 'Pinterest',
		url: 'https://www.pinterest.ca/foodnetworkCA/_created/',
		Icon: PinterestIcon,
	},
	youtube: {
		image: '/icons/youtube-social-icon.svg',
		displayName: 'Youtube',
		url: 'https://www.youtube.com/user/foodnetworkcanada',
		Icon: YoutubeIcon,
	},
	instagram: {
		image: '/icons/instagram-social-icon.svg',
		displayName: 'Instagram',
		url: 'https://www.instagram.com/foodnetworkca/',
		Icon: InstagramIcon,
	},
	twitter: {
		image: '/icons/twitter-social-icon.svg',
		displayName: 'Twitter',
		url: 'https://www.twitter.com/foodnetworkca/',
		Icon: TwitterIcon,
	},
	tiktok: {
		image: '/icons/tiktok-social-icon.svg',
		displayName: 'Tik Tok',
		url: 'https://www.tiktok.com/@foodnetworkca',
		Icon: TiktokIcon,
	},
	email: {
		image: '/icons/email-social-icon.svg',
		displayName: 'Email',
		Icon: EmailIcon,
	},
	print: {
		image: '/icons/print-social-icon.svg',
		displayName: 'Print',
		Icon: PrintIcon,
	},
};

export default Social;
